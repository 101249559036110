import { getLocale, intl } from 'di18n-react';
import React, { useEffect, useState } from 'react';
import { openNewWindow } from '@/utils/index';
import { Popover, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash-es';
import { CloseCircleFilled } from '@ant-design/icons';
import './index.less';

function CooperSurvey(props) {
  const { surveyInfo, isDrag } = props;
  const { userViewData } = useSelector((s) => s.GlobalData);
  const [visible, setVisible] = useState(false);
  const { setUserViewDataRq } = useDispatch().GlobalData;
  const [isShow, setIsShow] = useState(false);
  // const [isClickSmallIcon, setIsClickSmallIcon] = useState(true);

  useEffect(() => {
    setIsShow(userViewData.Survey_Open_2024_Q4);
  }, [userViewData.Survey_Open_2024_Q4]);

  // useEffect(() => {
  //   if (userViewData?.isClickSmallIcon) {
  //     setIsClickSmallIcon(userViewData.isClickSmallIcon)
  //     if (userViewData.isClickSmallIcon) {
  //       moveSurveyWarp(46)
  //       setVisible(false)
  //     }
  //   } else {
  //     setIsClickSmallIcon(false)
  //   }
  // }, [userViewData]);

  useEffect(() => {
    if (isDrag) {
      setVisible(false);
    }
  }, [isDrag]);

  const _renderContent = () => {
    return (
      <div className='v3-cooper-survey-desc'>
        { getLocale() === 'zh-CN' ? surveyInfo.desc_zh : surveyInfo.desc_en }
      </div>
    )
  }
  // const moveSurveyWarp = debounce((data) => {
  //   const moveTarget = document.querySelector('#cooper-survey-img');
  //   if (moveTarget) {
  //     moveTarget.style.transform = `translate(${data}px, 0)`;
  //   }
  // }, 100, { leading: true });
  const mouseInter = throttle(() => {
    if (!visible && !isDrag) {
      // if (isClickSmallIcon) {
      //   moveSurveyWarp(0);
      // }
      setVisible(true);
    }
  }, 300, { leading: true });
  const mouseLeave = throttle(() => {
    // if (!isDrag && isClickSmallIcon) {
    //   moveSurveyWarp(46);
    // }
    setVisible(false);
  }, 300, { leading: true });
  return (!isShow ? null
    : <div className='v3-cooper-survey-wrap'>
      <Tooltip
        title={intl.t('关闭')}
        placement='left'
        align={{ offset: [-6, 0] }}
        overlayClassName='cooper-survey-popover-window'
        >
        <CloseCircleFilled
          className='v3-cooper-survey-rightTop-icon'
          onClick={() => {
            let data = { ...userViewData };
            data.Survey_Open_2024_Q4 = false;
            setVisible(false)
            setIsShow(false)
            if (JSON.stringify(userViewData) === '{}') return;
            setUserViewDataRq(data);
          }}
        />
      </Tooltip>
      <Popover
        overlayClassName={
          `${navigator.userAgent.includes('Win') ? 'cooper-survey-popover-window cooper-survey-popover' : 'cooper-survey-popover'}`
        }
        visible={visible}
        content={_renderContent()}
        placement='left'
        zIndex={1110}
      >
        <div className='v3-cooper-survey'>
          <img
            src={getLocale() === 'zh-CN' ? surveyInfo.img_zh : surveyInfo.img_en}
            alt='调研'
            id='cooper-survey-img'
            onClick={() => {
              if (isDrag) {
                return;
              }
              openNewWindow(surveyInfo.url);
              setVisible(false);
            }}
            onMouseEnter={mouseInter}
            onMouseLeave={mouseLeave}
          />
        </div>
      </Popover>
    </div>
  );
}

export default CooperSurvey;
