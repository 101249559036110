import classBind from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import CooperSurvey from './CooperSurvey';
import { useSelector, useDispatch } from 'react-redux';
import CooperApi from '@/utils/request/api/CooperApi';
import { get } from '@/utils/request';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const TOP = 100;

const positionName = process.env.APP_ENV === 'prod' ? 'survey-202411' : process.env.APP_ENV === 'qa' ? 'qa-survey-202411' : 'test-survey-202411'

function GlobalUtils(props) {
  const { setUserViewDataRq } = useDispatch().GlobalData;
  const { IsExternalTenant, userViewData } = useSelector((state) => state.GlobalData);
  const { surveyInfo } = props;
  const { pathname } = useLocation();
  const [position, setPosition] = useState(null);
  const [isDrag, setIsDrag] = useState(false);
  const [isWriteSurvey, setIsWriteSurvey] = useState(true);
  useEffect(() => {
    const { surveyInfoTop } = userViewData;
    if (surveyInfoTop) {
      setPosition(surveyInfoTop)
    } else {
      setPosition(TOP)
    }
  }, [userViewData])

  useEffect(() => {
    if (surveyInfo?.open) {
      get(CooperApi.APPLY_NOTICE_UNREAD.replace(':position', positionName), {
        params: {
          frontType: 'WEB',
          noticeType: 'INSTRUCTION',
        },
        headers: {
          'X-App-Id': 2,
        },
      }).then((res) => {
        if (JSON.stringify(res) === '{}') {
          setIsWriteSurvey(true);
        } else {
          setIsWriteSurvey(false)
        }
      })
    }
  }, [surveyInfo])
  const isShowSurvey = () => {
    if (pathname === '/' || pathname.includes('/disk') || pathname.includes('/files/') || pathname.includes('/team-folder') || pathname.includes('/team-file/') || pathname.includes('/tome') || pathname.includes('/fromme') || pathname.includes('/favorite') || pathname.includes('/trash')) {
      return true;
    }
    return false;
  }
  return (
    <>
      {
        // eslint-disable-next-line max-len
        surveyInfo?.open && !isWriteSurvey && position && !IsExternalTenant && isShowSurvey() && (
          <Draggable
            position={{ x: 0, y: position }}
            axis='y'
            onDrag={() => {
              setIsDrag(true);
            }}
            onStop={(e, ui) => {
              if (ui.y < 0) {
                setPosition(20);
                let data = { ...userViewData };
                data.surveyInfoTop = 20;
                if (JSON.stringify(userViewData) === '{}') return;
                setUserViewDataRq(data);
              } else if (ui.y > (document.body.clientHeight - 48)) {
                setPosition(document.body.clientHeight - 48)
                let data = { ...userViewData };
                data.surveyInfoTop = document.body.clientHeight - 48;
                if (JSON.stringify(userViewData) === '{}') return;
                setUserViewDataRq(data);
              } else {
                setPosition(ui.y);
                let data = { ...userViewData };
                data.surveyInfoTop = ui.y;
                if (JSON.stringify(userViewData) === '{}') return;
                setUserViewDataRq(data);
              }
              setTimeout(() => {
                setIsDrag(false);
              }, 400);
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div
              className={cx('global-survey-wrap')}
              // style={{
              //   top: position,
              // }}
            >
              <CooperSurvey
                isDrag={isDrag}
                surveyInfo={surveyInfo}
              />
            </div>
          </Draggable>
        )
      }
    </>
  )
}

export default GlobalUtils
